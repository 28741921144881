import { ConstructionOutlined, MarkEmailReadTwoTone } from '@mui/icons-material';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { LangContext, useLangContext } from 'context/useLang';
import { MarketContext, useMarketContext } from 'context/useMarketContext';
import { Document } from 'models/document';
import React, { useContext } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { getLangByCode } from 'utils/utils';
import { CardCatalog } from './cardCatalog';
import { Book, Lang, Language, Market } from './component';
import { getAll } from 'services/axios';

interface CardListProps {
  search?: string;
  /**
   * 
   */
  value: number;

  /**
   * 
   */
  center?: boolean;

  /**
   * 
   */
  scrollable?: boolean;

}

export function CardList(props: CardListProps) {

  const {
    value, center = false, scrollable = false, search
  } = props;

  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [documents, setDocuments] = React.useState<Document[]>([]);

  React.useEffect(() => {
    setDocuments([]);
    getAll<Document[]>('document', undefined, 'param', 
      { 
        language: searchParams.get('lg')?.replace('_','-'), 
        ...searchParams.get('market') ? {market: searchParams.get('market')} : {},
        ...value > 0 ? { category: value } : {}
    })
      .then(res => {

        setDocuments(res.data.filter(doc => {
          if (search && doc.documentVersion.length > 0) {
            return doc.documentVersion[0].label?.toLowerCase().includes(search.toLocaleLowerCase())
          } else {
            return doc
          }
        })) 
      });
  }, [searchParams.get('lg'), searchParams.get('market'), value, search]);

  return (
    <>
      {documents.map((document, i) => 
        <Grid container key={i}
          direction='row' justifyContent={center ? 'center' : 'unset'}
          item lg={2} md={3} sm={4} px={1} py={1} xs={10}>
            <CardCatalog document={document} isNew={value === 0 && i < 3} />
        </Grid>
      )}
    </>
  )
}