import { Lang, Market } from 'components/component';
import React from 'react';

export interface LangContext {
  /**
   * 
   */
  langs: Lang[];
  /**
   * 
   */
  setLangs: Function;
  /**
   * 
   */
  lang: Lang | null | undefined;
  /**
   * 
   */
  setLang: Function;
  /**
   * 
   */
}

const context = React.createContext<LangContext>({
  langs: [],
  setLangs: () => {},
  lang: null,
  setLang: () => {}
});

/**
 * 
 * @returns 
 */
export function useLangContext() {
  return context;
}