import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

interface CardContainerProps {
  children: React.ReactNode | React.ReactNode[] | boolean
}

export function CardContainer(props: CardContainerProps) {
  const {
    children
  } = props;

  const theme = useTheme();

  return (
    <Grid container item xs={12} py={1} justifyContent='center' sx={{ maxWidth: '100%', overflow: 'scroll' }}>
      {children && children}
    </Grid>
  )
}