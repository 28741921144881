import React from 'react';
import { css } from '@emotion/css'
import { BE, BR, CN, CZ, DE, ES, FR, GB, HU, IT, NL, PL, PT, RU, US } from 'country-flag-icons/react/3x2'
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';

interface CountryFlag {
  /**
   * Lang code. 
   * ISO 3166-1
   * @link https://fr.wikipedia.org/wiki/ISO_3166-1
   */
  lang: string;
  /**
   * Display or not country flag label
   */
  displayLabel?: boolean;
  /**
   * Only for mobile viewport select component rendering value 
   */
  selectRendering?: boolean;
  /**
   * 
   */
  large?: boolean;
  /**
   * 
   */
  displayFlag?: boolean;
  /**
   * 
   */
  label?: string;
  /**
   * 
   */
  marketCode?: string;
}

export function CountryFlag(props: CountryFlag) {
  const {
    lang,
    displayLabel = false, selectRendering = false, large = false,
    displayFlag = true,
    label, marketCode
  } = props;

  const cssFlagComponent = css`
    width: ${!large ? '15px' : '20px'}; 
    height: ${!large ? '10px' : '15px'}; 
    margin-right: ${!large ? '5px' : '17.5px'};
    margin-left: ${!large ? '5px' : '17.5px'};
  `

  switch (lang) {
    case "fr-FR":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <FR className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "en-US":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <US className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "en-GB":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <GB className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "es-ES":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <ES className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "zh-CN":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <CN className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "de-DE":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <DE className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "it-IT":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <IT className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "pt-PT":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <PT className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "pt-BR":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <BR className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "ru-RU":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <RU className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "hu-HU":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <HU className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "pl-PL":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <PL className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "nl-NL":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <NL className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "nl-BE":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <BE className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    case "cs-CZ":
      return (
        <Grid container alignItems='center' sx={{ height: '100%', ':hover': { cursor: 'pointer' } }} wrap='nowrap'>
          {displayFlag && <CZ className={cssFlagComponent} />}
          {marketCode && <Box sx={{ pr: '10px' }}><Typography variant='body2'>{marketCode}</Typography></Box>}
          {displayLabel && <Box sx={{ display: (selectRendering ? { xs: 'none', md: 'flex' } : {}) }}><Typography variant='body2'>{label}</Typography></Box>}
        </Grid>
      )
    default:
      return <></>
  }
}