import React, { ChangeEventHandler, MouseEvent, useContext } from 'react';
import { AppBar, FormControl, Grid, MenuItem, Popover, Toolbar, Typography, useMediaQuery, InputAdornment, IconButton } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { css } from '@emotion/css'
import { Box } from '@mui/system';
import PAMLogo from 'images/PAM_logo_rgb.png';
import eCatalogLogo from 'images/logo_ecatalog.svg';
import { styled, alpha, useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { CountryFlag } from './countryFlag';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { Market } from 'models/market';
import { MarketLanguage } from 'models/marketLanguage';
import { get, getAll } from 'services/axios';
import ClearIcon from '@mui/icons-material/Clear';


const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#F27506', 0.15),
  '&:hover': {
    backgroundColor: alpha('#F27506', 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const DeleteIconWrapper = styled('div')(({ theme }) => ({
  zIndex: 9999,
  cursor: 'pointer',
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  right: '0',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#F27506',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

interface HeaderProps {
  /**
   * Search word
   */
  search: string;
  /**
   * 
   */
  onSearch: ChangeEventHandler<HTMLInputElement>

  clearSearch: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * 
 * @returns 
 */
export function Header(props: HeaderProps) {

  const { search, onSearch, clearSearch } = props;

  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const [open, setOpen] = React.useState(false);
  const [el, setEl] = React.useState<any>(null);
  const [market, setMarket] = React.useState<Market | undefined>()
  const [markets, setMarkets] = React.useState<Market[]>([]);

  React.useEffect(() => {
    getAll('market').then(res => setMarkets(res.data as Market[]))
  }, []);

  React.useEffect(() => {
    const marketSearch = searchParams.get('market');
    get<Market>('market', 'search', 'query', {
      lang: searchParams.get('lg')?.replace('_', '-'), ...marketSearch ? { market: marketSearch } : {}
    }
    ).then(res => setMarket(res.data));
  }, [searchParams.get('lg')]);

  /**
   * 
   * @param market_code 
   * @param language_code 
   */
  const onClick = (market_code: string, language_code: string) => {
    get<number>('market', 'count', 'param', { code: language_code })
      .then(res => {
        if (res.data > 1) {
          navigate(`?lg=${language_code.replace('-', '_')}&market=${market_code}`)
        } else {
          navigate(`?lg=${language_code.replace('-', '_')}`)
        }
      }).finally(() => setOpen(false))
  }

  /**
   * 
   * @returns 
   */
  const getLangMenu = () => {
    return (<Box sx={{ '& :hover': { cursor: 'pointer' } }}>
      <FormControl size='small'>
        <div onClick={(event) => { setOpen(true); setEl(event.currentTarget); }} className={css`border: 1px solid #F27506; padding: 8.5px 14px; border-radius: 4px; display: flex; align-items: center; color: #F27506;`}>
          {market && <CountryFlag marketCode={market.code} selectRendering lang={market.languages[0].language.code} displayLabel={true} label={market.languages[0].language.label} />}
          <ArrowDropDownIcon className={css`color: #F27506;`} />
        </div>
        <Popover
          sx={{ '& .MuiPaper-root': { minWidth: '30%', maxWidth: { md: '60%', sm: '50%', lg: '50%' } } }}
          open={open} onClose={() => setOpen(false)} anchorEl={el}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Grid container flexWrap='wrap' p={1}>
            {markets.map((item: Market, i) =>
              <Grid item container key={i} xl={3} lg={3} md={4} sm={6} xs={6} p={1}>
                <Typography variant='body1'
                  className={css`
                    position: relative;
                    width: 100%;
                    white-space: nowrap;
                    margin-left: 17.5px !important; 
                    &:before { 
                      content: ''; 
                      position: absolute;
                      bottom: 0;
                      border: 1px solid #175DC3; 
                      width: 25%;
                    }`
                  }>{item.label}</Typography>
                {item.languages.map((ml: MarketLanguage) =>
                  <MenuItem value={ml.language.code} onClick={() => onClick(item.code, ml.language.code)}>
                    <CountryFlag key={i} label={ml.language.label} lang={ml.language.code} displayLabel={true} />
                  </MenuItem>
                )}
              </Grid>
            )}
          </Grid>
        </Popover>
      </FormControl>
    </Box>)
  }

  const isPanelAdmin = window.location.pathname.includes('/admin')

  return (
    <AppBar position='static' sx={{ backgroundColor: '#fff' }}>
      <Toolbar sx={{ flexWrap: 'wrap', pb: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ width: { xs: '40%', sm: '150px' } }}>
              <img 
                  onClick={() => window.location.href = `/?lg=${searchParams.get('lg')}${searchParams.get('market') ? `&market=${searchParams.get('market')}` : ''}`} 
                  className={css`width: 100%; margin-right: 15px; :hover { cursor: pointer; }`} 
                  src={PAMLogo} 
              />
          </Box>
          <Box sx={{ width: { xs: '40%', sm: '150px' } }}>
              <img 
                  onClick={() => window.location.href = `/?lg=${searchParams.get('lg')}${searchParams.get('market') ? `&market=${searchParams.get('market')}` : ''}`} 
                  className={css`width: 100%; margin-right: 15px; :hover { cursor: pointer; }`} 
                  src={eCatalogLogo} 
              />
          </Box>
        </Box>
        {!searchParams.get('document') && !window.location.pathname.includes('admin') && (xs || sm) &&
          (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <>
                {!isPanelAdmin && <Search sx={{ marginRight: '10px' }}>
                  <SearchIconWrapper>
                    <SearchIcon className={css`color: #F27506`} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={t('main.search')} inputProps={{ 'aria-label': 'search' }}
                    onChange={onSearch} value={search}
                    endAdornment={
                      <ClearIcon className={css`color: #F27506`} />
                    }
                  />

                </Search>}
                {getLangMenu()}
              </>
            </Box>
          )
        }
        {(md || xl || lg) && !window.location.pathname.includes('admin') && !searchParams.get('document') &&
          <>
            {!isPanelAdmin && <Search sx={{ marginRight: '10px' }}>
              <SearchIconWrapper>
                <SearchIcon className={css`color: #F27506`} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={t('main.search')} inputProps={{ 'aria-label': 'search' }}
                onChange={onSearch} value={search}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={clearSearch}>
                      <ClearIcon className={css`color: #F27506`} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Search>}
            <Box sx={{ flexGrow: 1 }} />
            {getLangMenu()}
          </>
        }
        {searchParams.get('document') && !window.location.pathname.includes('admin') &&
          <>
            <Box sx={{ flexGrow: 1 }} />
            {getLangMenu()}
          </>
        }
      </Toolbar>
    </AppBar>
  )
}