import { css } from '@emotion/css';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSearch } from 'App';
import { CardContainer } from 'components/cardContainer';
import { CardList } from 'components/cardList';
import { Footer } from 'components/footer/footer';
import { TabsMenu } from 'components/tabsMenu';
import { LangContext, useLangContext } from 'context/useLang';
import { MarketContext, useMarketContext } from 'context/useMarketContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { getAll } from 'services/axios';
import { HomePageProps } from './types/homePage';
import useCookieConsent from '../../context/useCookieConsent'

export function HomePage(props: HomePageProps) {
  const { search } = props;
  const [searchParams] = useSearchParams();

  const category = searchParams.get('category')
  const [value, setValue] = React.useState(category ? parseInt(category) : 0);

  useCookieConsent();

  // const getUrlBook = () => {
  //   return `${process.env.PUBLIC_URL}/books/${document}/${currentMarket.code}/${lang?.code}/index.html`
  // }

  return (
    <>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }} >
        <Grid container item xs={12} direction='column' flexWrap='nowrap'></Grid>
          <TabsMenu value={value} setValue={setValue} />
          <CardContainer>
            <CardList search={search} value={value} center />
          </CardContainer>
          <Footer />
        <Grid />
      </Box>
      {/* {document && currentMarket && lang &&
        <Grid container item xs={12} direction='column' flexWrap='nowrap'>
          <Grid item xs={0.5} py={2} px={2}>
            {getBreadcrumbs()}
          </Grid>
          <Grid item xs={12} sx={{ height: '82%', ml: '17.5px', mr: '17.5px', width: 'calc(100% - 35px)', maxWidth: 'calc(100% - 50px);' }}>
            <iframe height='100%' className={css`border: unset; width: calc(100%);`} src={getUrlBook()} />
          </Grid>
          <Grid container item xs={0.5} px={2} pt={2} pb={2}>
            <Grid item sx={{ ml: '17.5px', mr: '17.5px' }}>
              <Typography>{t('document.available')}</Typography>
            </Grid>
            {getFlags()}
          </Grid>
        </Grid>
      } */}
    </>
  )
}