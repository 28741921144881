import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
  Outlet
} from "react-router-dom";

import { LoginCallback, SecureRoute, Security, useOktaAuth } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { App } from 'App';
import { LegalInformationsPage } from "pages/legalInformations/legalInformationsPage";
import { LinksPage } from "pages/links/linksPage";
import { ContactPage } from "pages/contact/contactPage";
import { HomePage } from "pages/home/homePage";
import { BookPage } from "pages/book/book";
import { AdminPage } from "pages/admin/adminPage";
import { AddEditBookPage } from "pages/admin/addEditBook/addEditBookPage";
import { CookiePage } from "pages/legalInformations/cookiePage";
import { DataRights } from "pages/legalInformations/dataRights";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_ISSUER,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback'
})

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => { window.location.href = toRelativeUrl(originalUri, window.location.origin) }

/**
 * 
 * @param props 
 * @returns 
 */
export function AuthProvider (props: any) {
  const {
    children
  } = props;
  const { oktaAuth, authState } = useOktaAuth();

  if (authState && !authState.isAuthenticated) {
    if (!authState.isPending) {
      oktaAuth.signInWithRedirect({ originalUri: '/admin' })
    }
    return null;
  }

  if(authState && authState.isAuthenticated && authState.accessToken && authState.accessToken.accessToken) {
    window.localStorage.setItem('token', authState.accessToken.accessToken);
  }

  if (children) {
    return <>{children}</>;
  } else {
    return <Outlet />
  }
}

export function Routing() {
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route path='/' />
            <Route path='/legal-informations' element={<LegalInformationsPage />} />
            <Route path='/cookies' element={<CookiePage />} />
            <Route path='/data-rights' element={<DataRights />} />
            <Route path='/links' element={<LinksPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/login/callback' element={<LoginCallback />} />
            <Route path='/admin' element={<AuthProvider />}>
              <Route path='/admin' element={<AdminPage />} />
              <Route path='/admin/edit/:id' element={<AddEditBookPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Security>
  )
} 