import { useEffect } from 'react';

declare global {
  interface Window {
    OneTrust: any;
  }
}

const useCookieConsent = () => {
  useEffect(() => {
    const consentGiven = localStorage.getItem('OneTrustConsentGiven') === 'true';
    if (!consentGiven) {
      loadOneTrustConsentBanner();
    }
  }, []); 

  const loadOneTrustConsentBanner = () => {
    if (document.getElementById('onetrust-script')) {
      return; 
    }

    const script = document.createElement('script');
    script.id = 'onetrust-script';
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.setAttribute("data-domain-script", process.env.REACT_APP_ONETRUST_KEY || '');

    //console.log('cookie = ' + process.env.REACT_APP_ONETRUST_KEY);
    
    script.addEventListener('load', () => {
      if (window.OneTrust) {
        const consentGiven = localStorage.getItem('OneTrustConsentGiven') === 'true';
        if (!consentGiven) {
          window.OneTrust.ToggleInfoDisplay();
        }
      }
    });

    document.head.appendChild(script);
  };
};

export default useCookieConsent;