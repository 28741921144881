import React, { ChangeEvent } from 'react';
import { Box } from '@mui/system';
import { DataGrid, GridColDef, GridValueGetterParams, GridColumns, GridActionsCellItem, GridRowParams, MuiBaseEvent, GridCallbackDetails } from '@mui/x-data-grid';
import { get, getAxiosInstance } from 'services/axios'
import { Breadcrumbs, Button, FormControl, Grid, InputLabel, Link, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Delete, Edit, Home, List } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { Document } from 'models/document';
import { Category } from 'models/category';
import { post, put, del } from 'services/axios/request';
import { css } from '@emotion/css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function AdminPage() {

  const cssHeaderGrid = css`background-color: #A6A6A6`;

  /**
   * 
   */
  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1, headerClassName: cssHeaderGrid },
    { field: 'name', headerName: 'Technical name / Folder name', flex: 1, headerClassName: cssHeaderGrid },
    { field: 'date_create', type: 'date', headerName: 'Creation date', headerClassName: cssHeaderGrid, flex: 1, renderCell: (params) => new Date(params.row.publication_date).toLocaleDateString('en-US') },
    { field: 'last_update', type: 'date', headerName: 'Last update', headerClassName: cssHeaderGrid, flex: 1, renderCell: (params) => params.row.documentVersion.length > 0 ? new Date(params.row.documentVersion[0].update_date).toLocaleDateString('en-US') : '' },
    {
      field: 'actions', type: 'actions', width: 150, headerClassName: cssHeaderGrid, getActions: (params: GridRowParams) => [
        <GridActionsCellItem icon={<ContentCopyIcon />} onClick={() => handleCopyBookPath(params)} label='path' />,
        <GridActionsCellItem icon={<List />} onClick={() => handleList(params.id as number)} label='list' />,
        <GridActionsCellItem icon={<Edit />} onClick={() => handleEdit(params.id as number)} label='edit' />,
        <GridActionsCellItem icon={<Delete />} onClick={() => handleDelete(params.id as number)} label='delete' />
      ]
    }
  ];

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
  };

  const navigate = useNavigate();

  const [modal, setModal] = React.useState(false);

  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [categories, setCategories] = React.useState<Category[]>([]);

  const [document, setDocument] = React.useState<Document>()
  const [documentId, setDocumentId] = React.useState<number>(0)

  const [newEditLabel, setNewEditLabel] = React.useState<string>();
  const [newEditCategory, setNewEditCategory] = React.useState<number>();

  React.useEffect(() => {
    get<Document[]>('document').then(res => setDocuments(res.data));
    get<Category[]>('category', undefined, 'param', { code: 'en-US' }).then(res => setCategories(res.data));
  }, []);

  React.useEffect(() => {
    setDocument(documents.find(document => document.id === documentId));
  }, [documentId]);

  React.useEffect(() => {
    if (document) {
      setNewEditLabel(document.name);
      setNewEditCategory(document.category.id);
      handleOpenModal();
    }
  }, [document])

  const parseDateString = (strDate: string): string => {
    const date = new Date(strDate);
    return `${date.toLocaleDateString('fr-FR')}`
  }

  /**
   * 
   * @returns 
   */
  const handleOpenModal = () => setModal(!modal);

  /**
   * 
   * @returns 
   */
  const handleCloseModal = () => {
    setDocument(undefined);
    setDocumentId(0);
    setModal(false);
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  const handleEdit = (id: number) => setDocumentId(id);

  /**
   * 
   * @param id 
   * @returns 
   */
  const handleList = (id: number) => navigate(`edit/${id}`)

  /**
   * 
   * @param event 
   * @returns 
   */
  const handleSetLabel = (event: ChangeEvent<HTMLInputElement>) => setNewEditLabel(event.target.value);

  /**
   * 
   * @param event 
   * @returns 
   */
  const handleSetCategory = (event: SelectChangeEvent, child: React.ReactNode) => setNewEditCategory(parseInt(event.target.value));

  const handleCopyBookPath = (params: GridRowParams) => {
    window.navigator.clipboard.writeText(`/applications/ecatalog/books/${params.row.name}`)
  }

  /**
   * 
   */
  const handleSave = () => {
    if (documentId) {
     if (newEditLabel && newEditCategory) {
      put<Document>('document', { id: documentId, name: newEditLabel, id_category: newEditCategory }, 'update')
        .then(res => get<Document[]>('document')
        .then(res => setDocuments(res.data)))
        .finally(() => handleCloseModal())
     }
    } else {
      if (newEditLabel && newEditCategory) {
        post<Document>('document', {name: newEditLabel, id_category: newEditCategory }, 'create')
        .then(res => get<Document[]>('document')
        .then(res => setDocuments(res.data)))
        .finally(() => handleCloseModal());
      }
    }
  }

  /**
   * 
   */
  const handleDelete = (id: number) => {
    const confirmed = window.confirm('Do you confirm to delete this document ?')
    if(confirmed) {
      if(id) {
        del('document', id, 'delete').then(res => get<Document[]>('document').then(res => setDocuments(res.data)));
      }
    }
  }

  return (
    <Grid container item xs={12} sx={{ height: '91vh' }} justifyContent='center'>
      <Grid container direction={'column'} height={'100%'} width={'95%'} flexWrap='nowrap'>
      <Breadcrumbs sx={{ mt: 2 }}>
        <Link href='/'><Home /></Link>
        <Link href='/admin'> Admin Panel </Link>
      </Breadcrumbs>
        <Grid container item xs={1} justifyContent='flex-end' alignItems='center'>
          <Grid container item xs={10} justifyContent='flex-start' alignItems='center'>
            <Typography variant='h5'>Document list</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent='flex-end' alignItems='center'>
            <Button variant='contained' onClick={handleOpenModal}>Add a document</Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataGrid columns={columns} rows={documents} columnVisibilityModel={{ id: false }} />
        </Grid>
        <Modal open={modal} onClose={handleCloseModal}>
          <Box sx={style} flex={1}>
            {documentId > 0 && <Typography>Edit an existing document</Typography>}
            {documentId === 0 && <Typography>Add an document</Typography>}
            <Box flex={1}>
              <FormControl fullWidth sx={{ my: 1 }}>
                <TextField margin='dense' variant='standard' label='Document name' onChange={handleSetLabel} value={newEditLabel} />
              </FormControl>
              <FormControl fullWidth sx={{ my: 1 }}>
                <InputLabel>Category</InputLabel>
                <Select label='Category' onChange={handleSetCategory} required value={newEditCategory?.toString()}>
                  {categories.map((category, i) =>
                    <MenuItem key={i} value={category.id}>{category.categoryTranslation.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <Button sx={{ my: 1 }} variant='contained' onClick={handleSave}>Save</Button>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  )
}