import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getMarketParam = (lg : any) => {
    return lg === 'en_us' ? 'INT' : searchParams.get('market');
  };

  const constructUrl = (path : any) => {
    const lg = searchParams.get('lg');
    const market = getMarketParam(lg);
    return `${path}?lg=${lg}${market ? `&market=${market}` : ''}`;
  };

  return (
    <>
      <Grid container item xs={12} sx={{ px: '20px', py: '5px', position: 'relative' }} alignItems='center'>
        <Grid container item xs={12} justifyContent='center' alignItems='center' textAlign='center'>
          <Grid item lg={2} md={3} py={1} sm={4} xs={7}>
            <Typography
              onClick={() => navigate(constructUrl('/legal-informations'))}
              fontWeight='600'
              variant='caption'
              textTransform='uppercase'
              sx={{
                position: 'relative',
                ':hover': {
                  cursor: 'pointer',
                  ':before': { content: '""', width: '50%', border: '1px solid #175DC3', position: 'absolute', bottom: '-5px' }
                }
              }}
            >
              {t('footer.legal')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              onClick={() => navigate(constructUrl('/links'))}
              fontWeight='600'
              variant='caption'
              textTransform='uppercase'
              sx={{
                position: 'relative',
                ':hover': {
                  cursor: 'pointer',
                  ':before': { content: '""', width: '50%', border: '1px solid #175DC3', position: 'absolute', bottom: '-5px' }
                }
              }}
            >
              {t('footer.links')}
            </Typography>
          </Grid>
          <Grid item lg={2} md={3} py={1} sm={4} xs={7}>
            <Typography
              onClick={() => navigate(constructUrl('/cookies'))}
              fontWeight='600'
              variant='caption'
              textTransform='uppercase'
              sx={{
                position: 'relative',
                ':hover': {
                  cursor: 'pointer',
                  ':before': { content: '""', width: '50%', border: '1px solid #175DC3', position: 'absolute', bottom: '-5px' }
                }
              }}
            >
              {t('footer.cookies')}
            </Typography>
          </Grid>
          <Grid item lg={2} md={3} py={1} sm={4} xs={7}>
            <Typography
              onClick={() => navigate(constructUrl('/data-rights'))}
              fontWeight='600'
              variant='caption'
              textTransform='uppercase'
              sx={{
                position: 'relative',
                ':hover': {
                  cursor: 'pointer',
                  ':before': { content: '""', width: '50%', border: '1px solid #175DC3', position: 'absolute', bottom: '-5px' }
                }
              }}
            >
              {t('footer.data')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign='center' fontWeight={200} fontSize={13} fontStyle='italic'>
            {t('main.description')}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign='center'>
          <Typography fontSize='10px' variant='caption'>
            {new Date().getFullYear()}
            {t('footer.copyright')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
