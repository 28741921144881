import React, {useEffect} from 'react';
import { Footer } from 'components/footer/footer';
import { Breadcrumbs, Grid } from '@mui/material';
import { Link } from '@mui/material';
import { Home } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export function CookiePage() {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const loadScript = (src : any) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    }

    useEffect(() => {
      loadScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
    }, []);
    
  return (
    <>
    <Breadcrumbs sx={{ py: '7.5px', px: '17.5px' }}>
        <Link underline='hover' onClick={() => navigate(`/?lg=${searchParams.get('lg')}${searchParams.get('INT') ? `&market=${searchParams.get('INT')}` : ''}`, { replace: true })}>
            <Home />
        </Link>
        <Link  >
              {t('footer.cookies')}
        </Link>
        <Grid item xs={12} sx={{ml : 10, mt : 2, mr : 10}}>
          <div id="ot-sdk-cookie-policy"></div>
        </Grid>
    </Breadcrumbs>  
    <Footer />
    </>
  )
}