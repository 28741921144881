import { Home } from '@mui/icons-material';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { Footer } from 'components/footer/footer';
import { LangContext, useLangContext } from 'context/useLang';
import { MarketContext, useMarketContext } from 'context/useMarketContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';


export function LegalInformationsPage() {

  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const { market, currentMarket } = useContext<MarketContext>(useMarketContext());
  const { lang } = useContext<LangContext>(useLangContext());

  const [searchParams] = useSearchParams();

  const handleCookiesClick = () => {
    const language = searchParams.get('lg');
    const market = searchParams.get('market');
    navigate(`/cookies?lg=${language}${market ? `&market=${market}` : ''}`);
  };


  return (
    <>
      <Grid container xs={12} direction='column' flexWrap='nowrap'>
        <Grid item xs={0.5}>
          <Breadcrumbs sx={{ py: '7.5px', px: '17.5px' }}>
          <Link underline='hover' onClick={() => navigate(`/?lg=${searchParams.get('lg')}${searchParams.get('INT') ? `&market=${searchParams.get('INT')}` : ''}`, { replace: true })}>
              <Home />
            </Link>
            <Link underline='hover' >
              {t('footer.legal')}
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid container item xs={12} p={'17.5px'} alignContent='flex-start'>
          <Grid item xs={12}>
          <Typography variant='h5' sx={{fontWeight :'bold'}}> {t('legal.title')}</Typography>
          <br />
          </Grid>
          <Grid item xs={12}>
          <Typography variant='h5' sx={{fontWeight :'bold'}}> {t('main.pamline.name')}</Typography>
          <br />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.publisher.title')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1'> {t('legal.publisher.text')}</Typography>
              <Typography variant='body1'> {t('legal.publisher.text2')}</Typography>
              <Typography variant='body1'> {t('legal.publisher.text3')}</Typography>
              <Typography variant='body1'> {t('legal.publisher.text4')}</Typography>
              <Typography variant='body1'> {t('legal.publisher.text5')}</Typography>
              <Typography variant='body1'> {t('legal.publisher.text6')}</Typography>
              <Typography variant='body1'>
                {t('legal.publisher.text7')} 
                <Link href={`mailto:${t('legal.publisher.text7.2')}`}>
                  {t('legal.publisher.text7.2')}
                </Link>
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.publication.title')}</Typography>
              <Grid item xs={4}>
              <Typography variant='body1'> {t('legal.publication.text')}</Typography>
              <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.editorial.title')}</Typography>
              <Grid item xs={4}>
                <Typography variant='body1'> {t('legal.editorial.text')}</Typography>
                <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.accommodation.title')}</Typography>
              <Grid item xs={4}>
              <Typography variant='body1'> {t('legal.accommodation.text')}</Typography>
              <Typography variant='body1'> {t('legal.accommodation.text2')}</Typography>
              <Typography variant='body1'> {t('legal.accommodation.text3')}</Typography>
              <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.responsibility.title')}</Typography>
              <Grid item xs={6}>
              <Typography variant='body1'> {t('legal.responsibility.text')}</Typography>
              <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.intellectual.title')}</Typography>
              <Grid item xs={6}>
              <Typography variant='body1'> {t('legal.intellectual.text')}</Typography>
              <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.brand.title')}</Typography>
              <Grid item xs={6}>
              <Typography variant='body1'> {t('legal.brand.text')}</Typography>
              <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.links.title')}</Typography>
              <Grid item xs={6}>
              <Typography variant='body1'> {t('legal.links.text')}</Typography>
              <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}>
            <Link onClick={handleCookiesClick} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'inherit' }}>
              {t('legal.cookies.title')}
            </Link>
          </Typography>
              <Grid item xs={6}>
              <Typography variant='body1'> {t('legal.cookies.text')}</Typography>
              <br />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Typography variant='h6' sx={{fontWeight :'bold'}}> {t('legal.equality.title')}</Typography>
              <Grid item xs={6}>
              <Typography variant='body1'> {t('legal.equality.text')}</Typography>
              <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Footer />
      </Grid>
    </>
  )
}