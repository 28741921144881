import { Home } from '@mui/icons-material';
import { Breadcrumbs, Grid, Link } from '@mui/material';
import { Footer } from 'components/footer/footer';
import { LangContext, useLangContext } from 'context/useLang';
import { MarketContext, useMarketContext } from 'context/useMarketContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

export function LinksPage() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { market, currentMarket } = useContext(useMarketContext());
  const { lang } = useContext(useLangContext());

  const linkStyle = {
    textDecoration: 'none',
    color: 'primary.main',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  return (
    <>
      <Grid container xs={12} direction='column' flexWrap='nowrap'>
        <Grid item xs={0.5}>
          <Breadcrumbs sx={{ py: '7.5px', px: '17.5px' }}>
            <Link
              underline='hover'
              sx={linkStyle}
              onClick={() =>
                navigate(
                  `/?lg=${searchParams.get('lg')}${searchParams.get('INT') ? `&market=${searchParams.get('INT')}` : ''}`,
                  { replace: true }
                )
              }
            >
              <Home />
            </Link>
            <Link underline='hover' sx={linkStyle}>
              {t('footer.links')}
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid container item xs={12} p={'17.5px'} alignContent='flex-start'>
          <Grid item xs={12} py={1}>
            {t('links.sg.title')} :{' '}
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.sg.url')}`)}>
              {t('links.sg.label')}
            </Link>
          </Grid>
          <Grid item xs={12} py={1}>
            {t('links.pam.title')}
          </Grid>
          <Grid item xs={12}>
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.pam.url1')}`)}>
              {t('links.pam.label1')}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.pam.url2')}`)}>
              {t('links.pam.label2')}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.pam.url3')}`)}>
              {t('links.pam.label3')}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.pam.url4')}`)}>
              {t('links.pam.label4')}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.pam.url5')}`)}>
              {t('links.pam.label5')}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.pam.url6')}`)}>
              {t('links.pam.label6')}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link sx={linkStyle} onClick={() => window.open(`${t('links.pam.url7')}`)}>
              {t('links.pam.label7')}
            </Link>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </>
  );
}
