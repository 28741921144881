import React from 'react';
import { Footer } from 'components/footer/footer';
import { Breadcrumbs, Grid, Typography, Link } from '@mui/material';
import { Home } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function DataRights() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const lg = searchParams.get('lg');


    const handleCookiesClick = () => {
        const language = searchParams.get('lg');
        const market = searchParams.get('market');
        navigate(`/cookies?lg=${language}${market ? `&market=${market}` : ''}`);
      };

    return (
        <>
            <Breadcrumbs sx={{ py: '7.5px', px: '17.5px', marginBottom: 3 }}>
            <Link underline='hover' onClick={() => navigate(`/?lg=${searchParams.get('lg')}${searchParams.get('INT') ? `&market=${searchParams.get('INT')}` : ''}`, { replace: true })}>
                    <Home />
                </Link>
                <Typography color="text.primary">{t('footer.data')}</Typography>
            </Breadcrumbs>  

            <Grid container spacing={3} sx={{ mx: 'auto', width: '90%', maxWidth: 1200, marginTop: 5, marginBottom: 10, marginLeft : 5 }}>
                
                <Grid item xs={12}>
                    <Typography variant='h4' sx={{ marginBottom: 2 }}> {t('footer.data')} </Typography>
                    <Typography variant='h6' sx={{ marginBottom: 2, color : 'lightgray' }}>{t('main.pamline.name')}</Typography>
                    <Typography variant='body1'> {t('data.text')}</Typography>
                    
                </Grid>

                <hr style={{ width: '100%', border: 0, borderTop: '1px solid #ccc', marginTop: '20px' }} />

                <Grid item xs={12}>
                    <Typography variant='h5'> {t('data.policy.title')}</Typography>
                    <Typography variant='body1'>
                        <Link href="https://www.saint-gobain.com/fr/politique-privacy" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                            {t('data.policy.click')}
                        </Link>
                        {' ' + t('data.policy.text')}
                    </Typography>
                </Grid>

                <hr style={{ width: '100%', border: 0, borderTop: '1px solid #ccc', marginTop: '20px' }} />

                <Grid item xs={12}>
                    <Typography variant='h5'> {t('data.treatment.title')}</Typography>
                    <Typography variant='body1'> {t('data.treatment.text')}</Typography>
                    <Link href={`${process.env.PUBLIC_URL}/public/sgpam_canalisation_demande_information_produits_et_services.pdf`} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                        {t('data.treatment.link1')}
                    </Link>
                    <br />
                    <Link href={`${process.env.PUBLIC_URL}/ecatalog-2022/public/sgpam_canalisation_envoi_dinformations_et_communications_commerciales.pdf`} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                        {t('data.treatment.link2')}
                    </Link>
                </Grid>

                <hr style={{ width: '100%', border: 0, borderTop: '1px solid #ccc', marginTop: '20px'}} />

                <Grid item xs={12}>
                    <Typography variant='h5'> {t('footer.cookies')}</Typography>
                    <Typography variant='body1'>{t('data.cookies.text')}</Typography>
                    <Typography variant='body1'>
                    <Link onClick={handleCookiesClick}
                        sx={{textDecoration:'none', color: 'primary.main', cursor:'pointer'}}>
                        {t('data.cookies.link')}
                    </Link>
                    </Typography>

                </Grid>

                <hr style={{ width: '100%', border: 0, borderTop: '1px solid #ccc', marginTop: '20px' }} />

                <Grid item xs={12}>
                    <Typography variant='h5'> {t('footer.contact')}</Typography>
                    <Typography variant="body1">
                        {t('data.contactInfoEmail')}
                    </Typography>
                    <Link href={`mailto:${t('data.contactEmail')}`} variant="body1" sx={{ display: 'block', color: 'primary.main', textDecoration: 'none' }}>
                        {t('data.contactEmail')}
                    </Link>
                    <Typography variant="body1">
                        {t('data.contactInfoPostal')}
                    </Typography>
                    <Typography variant="body1">
                        {t('data.contactAddress')}
                    </Typography>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}
