import { Grid, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CardContainer } from './cardContainer';
import { CardList } from './cardList';
import { Home } from '@mui/icons-material';
import { Book, Lang, Language } from './component';
import { getCategoriesByLang } from 'utils/utils';
import { MarketContext, useMarketContext } from 'context/useMarketContext';
import { LangContext, useLangContext } from 'context/useLang';
import { Footer } from './footer/footer';
import { get, getAll } from 'services/axios';
import { Category } from 'models/category';

interface TabsMenuProps {
  /**
   * 
   */
  value: number;

  /**
   * 
   */
  setValue: Function;
}

/**
 * TabsMenu component
 * Display categories and tabs categories
 * @param props 
 * @returns 
 */
export function TabsMenu(props: TabsMenuProps) {
  const { value, setValue } = props;

  const [searchParams] = useSearchParams();

  const [categories, setCategories] = React.useState<Category[] | undefined>()

  React.useEffect(() => {
    // Looking for categories by current language
    getAll<Category[]>('category', undefined, 'param', { code: searchParams.get('lg')?.replace('_', '-') })
      .then(res => setCategories(res.data));
  }, [searchParams.get('lg')]);


  /**
   * onChange event
   * Change tabs value to switch between tab
   * @param event 
   * @param newValue 
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => setValue(newValue);

  /**
   * 
   * @returns 
   */
  const renderingTabs = () => {
    return categories?.map((category: Category, index: number) => {
      return <Tab sx={{ color: '#646363', fontWeight: '600' }} value={category.id} key={index + 1} label={category.categoryTranslation.label} />
    })
  }

  return (
    <>
      {categories && <Tabs value={value} onChange={handleOnChange} variant="scrollable" allowScrollButtonsMobile
        sx={{ paddingBottom: '20px', '& .Mui-selected': { color: '#F27506 !important' }, '& .MuiTabs-indicator': { backgroundColor: '#F27506' } }}>
        <Tab value={0} icon={<Home />} />
        {renderingTabs()}
      </Tabs>}
    </>
  )
}