import { Home } from '@mui/icons-material';
import { Breadcrumbs, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridColumns, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { Document } from 'models/document';
import React, { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'services/axios';
import { Delete, Edit } from '@mui/icons-material';
import { Market } from 'models/market';
import { MarketLanguage } from 'models/marketLanguage';
import { del, post, put } from 'services/axios/request';
import { DocumentVersion } from 'models/documentVersion';
import LinkIcon from '@mui/icons-material/Link';
import { css } from '@emotion/css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export function AddEditBookPage() {

  const cssHeaderGrid = css`background-color: #A6A6A6`;


  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1, hideable: true },
    { field: 'label', headerName: 'Title', headerClassName: cssHeaderGrid, flex: 1 },
    { field: 'marketLanguage.language.label', headerClassName: cssHeaderGrid, headerName: 'Language', flex: 1, renderCell: (params) => params.row.marketLanguage.language.label },
    { field: 'marketLanguage.market.label', headerClassName: cssHeaderGrid, headerName: 'Market', flex: 1, renderCell: (params) => params.row.marketLanguage.market.label },
    { field: 'update_date', headerName: 'Update date', headerClassName: cssHeaderGrid, flex: 1, renderCell: (params) => new Date(params.row.update_date).toLocaleDateString('en-US') },
    {
      field: 'actions', type: 'actions', width: 150, headerClassName: cssHeaderGrid, getActions: (params: GridRowParams) => [
        <GridActionsCellItem icon={<ContentCopyIcon />} onClick={() => handleCopyBookPath(params)} label='path' />,
        <GridActionsCellItem icon={<LinkIcon />} label={'link'} onClick={() => handleOpenBook(params.row)} />,
        <GridActionsCellItem icon={<Edit />} label='edit' onClick={() => handleEdit(params.id as number)} />,
        <GridActionsCellItem icon={<Delete />} label='delete' onClick={() => handleDelete(params.id as number)} />
      ]
    }
  ]

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
  };

  const { id } = useParams();

  const [document, setDocument] = React.useState<Document | undefined>();
  const [languages, setLanguages] = React.useState<MarketLanguage[] | undefined>();
  const [markets, setMarkets] = React.useState<Market[]>([]);

  const [idVersion, setIdVersion] = React.useState<number>(-1);
  const [modal, setModal] = React.useState(false);

  // 
  const [languageSelected, setLanguageSelected] = React.useState<string | undefined>()
  const [marketSelected, setMarketSelected] = React.useState<string | undefined>()
  const [label, setLabel] = React.useState<string | undefined>()
  const [file, setFile] = React.useState<any>()
  const [promote, setPromote] = React.useState<boolean>(true);

  /**
   * 
   */
  React.useEffect(() => {
    get<Document>('document', undefined, 'param', { id }).then(res => setDocument(res.data));
    get<Market[]>('market').then(res => setMarkets(res.data));
  }, []);

  /**
   * 
   */
  React.useEffect(() => {
    if (idVersion > -1) {
      setModal(!modal);
    }

    if (idVersion > 0) {
      setMarketSelected(document?.documentVersion.find(version => version.id === idVersion)?.marketLanguage.market.id.toString())
    } else {
      setMarketSelected(undefined);
      setLanguages(undefined);
    }
  }, [idVersion]);

  /**
   * 
   */
  React.useEffect(() => {
    if (marketSelected) {
      get<Market>('market', 'findById', 'param', { marketSelected }).then(res => setLanguages(res.data.languages));
    }
  }, [marketSelected])

  /**
   * 
   */
  React.useEffect(() => {
    const id = document?.documentVersion.find(version => version.id === idVersion)?.marketLanguage.language.id;
    if (id) {
      setLanguageSelected(languages?.find(lg => lg.language.id === id)?.language.id.toString())
    }
  }, [languages]);

  const handleOpenModal = () => {
    setIdVersion(idVersion === 0 ? -1 : 0);
  }

  /**
   * 
   * @returns 
   */
  const handleCloseModal = () => setModal(false);

  /**
   * 
   * @param id 
   * @returns 
   */
  const handleEdit = (id: number) => setIdVersion(id);

  /**
   * 
   * @param id 
   */
  const handleDelete = (idItem: number) => {
    const confirmed = window.confirm('Do you confirm to delete this document ?')
    if (confirmed) {
      del(`document`, idItem, 'delete-item').then(res => get<Document>('document', undefined, 'param', { id }).then(res => setDocument(res.data)))
    }
  }

  const handleOpenBook = (params: DocumentVersion) => {
    window.open(window.location.origin + `?document=${document?.name}&lg=${params.marketLanguage.language.code.replace('-', '_')}&market=${params.marketLanguage.market.code}`, '_blank')
  }

  /**
   * 
   * @param event 
   * @param child 
   * @returns 
   */
  const handleSetLanguage = (event: SelectChangeEvent, child: React.ReactNode) => setLanguageSelected(event.target.value)

  /**
   * 
   * @param event 
   * @param child 
   * @returns 
   */
  const handleSetMarket = (event: SelectChangeEvent, child: React.ReactNode) => setMarketSelected(event.target.value)

  /**
   * 
   * @param event 
   * @returns 
   */
  const handleSetFile = (event: ChangeEvent<HTMLInputElement>) => setFile(event.target.files);

  /**
   * 
   * @param event 
   * @returns 
   */
  const handleSetLabel = (event: ChangeEvent<HTMLInputElement>) => setLabel(event.target.value);

  const handleCopyBookPath = (params: GridRowParams) => {
    console.log(params)
    window.navigator.clipboard.writeText(`/applications/ecatalog/books/${document?.name}/${params.row.marketLanguage.market.code}/${params.row.marketLanguage.language.code}`)
  }

  /**
   * 
   */
  const handleSave = () => {
    if (marketSelected && languageSelected) {
      get<Market>('market', 'searchById', 'param', { marketSelected, languageSelected }).then(
        async res => {
          let cover: string | undefined = undefined;
          // Read file
          if (file) {
            cover = await helperGetBase64File();
          }

          if (idVersion > 0) {
            if (idVersion && res.data.languages[0].id, label, promote) {
              put<any>('document',
                { id: idVersion, id_market_lang: res.data.languages[0].id, ...cover ? { cover: cover } : {}, label, promote },
                'update-item',
                { market: res.data.code, language: res.data.languages[0].language.code }
              ).then(res => get<Document>('document', undefined, 'param', { id }).then(res => setDocument(res.data))).finally(() => setModal(false))
            }
          } else {
            post<DocumentVersion>('document',
              { id_document: id as any as number, id_market_lang: res.data.languages[0].id, cover, label },
              'create-item',
              { market: res.data.code, language: res.data.languages[0].language.code }
            ).then(res => get<Document>('document', undefined, 'param', { id }).then(res => setDocument(res.data))).finally(() => setModal(false))
          }
        }
      )
    }
  }

  /**
   * 
   * @returns 
   */
  const helperGetBase64File = (): Promise<string> => new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsDataURL(file[0]);
    fr.onload = () => resolve(fr.result as string);
    fr.onerror = reject;
  })

  return (
    <>
      {document &&
        <Grid container item xs={12} sx={{ height: '91vh' }} justifyContent='center'>
          <Grid container direction={'column'} height={'75%'} width={'95%'} flexWrap='nowrap'>
            <Breadcrumbs sx={{ mt: 2 }}>
              <Link href='/'><Home /></Link>
              <Link href='/admin'> Admin Panel </Link>
              <Link href={`/admin/edit/${id}`}>Document version</Link>
            </Breadcrumbs>
            <Grid container item xs={1} justifyContent='flex-end' alignItems='center'>
              <Grid container item xs={10} justifyContent='flex-start' alignItems='center'>
                <Typography variant='h5'>Document version list</Typography>
              </Grid>
              <Grid container item xs={2} justifyContent='flex-end' alignItems='center'>
                <Button variant='contained' onClick={handleOpenModal}>Add a document version</Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataGrid columns={columns} rows={document.documentVersion} columnVisibilityModel={{ id: false }} />
            </Grid>
            <Modal open={modal} onClose={handleCloseModal}>
              <Box sx={style} flex={1}>
                {idVersion > 0 && <Typography>Edit an existing document version</Typography>}
                {idVersion === 0 && <Typography>Add an document version</Typography>}
                <Box flex={1}>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <TextField required type='file' size='small' onChange={handleSetFile} />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <TextField label='Title' value={label} defaultValue={document.documentVersion.find(version => version.id === idVersion)?.label} onChange={handleSetLabel} />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <InputLabel>Market</InputLabel>
                    <Select label='Market' required
                      onChange={handleSetMarket} value={marketSelected}
                      defaultValue={document.documentVersion.find(version => version.id === idVersion)?.marketLanguage.market.id.toString()}>
                      {markets.map(market => <MenuItem value={market.id}>{market.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                  {languages && <FormControl fullWidth sx={{ my: 1 }}>
                    <InputLabel>Language</InputLabel>
                    <Select label='Language' required
                      onChange={handleSetLanguage} value={languageSelected}
                      defaultValue={document.documentVersion.find(version => version.id === idVersion)?.marketLanguage.language.id.toString()}>
                      {languages.map(lg => <MenuItem value={lg.language.id}>{lg.language.label}</MenuItem>)}
                    </Select>
                  </FormControl>}
                  {idVersion > 0 &&
                    <FormControl fullWidth sx={{ my: 1 }}>
                      <FormControlLabel control={<Checkbox checked={promote} onClick={() => setPromote(!promote)} />} label={'Promote on homepage'} />
                    </FormControl>
                  }
                  <Button sx={{ my: 1 }} variant='contained' onClick={handleSave}>Enregistrer</Button>
                </Box>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      }
    </>
  )
}