import React from 'react';
import { Grid } from '@mui/material';
import './App.css';
import { Outlet, useNavigate, useSearchParams, useOutletContext } from 'react-router-dom';
import { Header } from 'components/header';
import 'i18n/init'
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { get } from 'services/axios';
import { Market } from 'models/market';
import { BookPage } from 'pages/book/book';
import { HomePage } from 'pages/home/homePage';

export type ContextType = { search: string | null };

export const useSearch = (): ContextType => useOutletContext<ContextType>();

/**
 * Main page
 * Group all components
 * @returns 
 */
export function App() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const [document, setDocument] = React.useState<string | null>(null);
  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => ReactGA.pageview(window.location.pathname + window.location.search), []);

  React.useEffect(() => {
    const documentSearch = searchParams.get('document');
    const lgSearch = searchParams.get('lg') ?? process.env.REACT_APP_DEFAULT_LANGUAGE;
    const marketSearch = searchParams.get('market');
    const lg = process.env.REACT_APP_DEFAULT_LANGUAGE
    const market = process.env.REACT_APP_DEFAULT_MARKET;

    // If marketSearch paramter isn't defined in url, we only looking for data with lang
    // lgSearch parameter is always defined
    if (!marketSearch) {
      // Looking for how many times lgSearch parameter is present in markets data
      get<number>('market', 'count', 'param', { code: lgSearch?.replace('_', '-') })
        .then(res => {
          const count: number = res.data;
          // If count is superior to one, we can't determinate what market to use. 
          // So we use market and default language in .env file
          if (count > 1) {
            // To be careful, we check if default language is present than one more market
            get<number>('market', 'count', 'param', { code: process.env.REACT_APP_DEFAULT_LANGUAGE })
              .then(res => {
                if (res.data > 1) {
                  // If default language is present than one more market, we add market in url too
                  navigate(`?lg=${lg?.replace('-','_')}&market=${market}${documentSearch ? `&document=${documentSearch}`: ''}`);
                } else {
                  // If default language is present to one market, only language code is enought
                  navigate(`?lg=${lg?.replace('-','_')}${documentSearch ? `&document=${documentSearch}`: ''}`);
                }
              })
          } else {
            // if language parameter is enought to determinate market
            navigate(`?lg=${lgSearch}${documentSearch ? `&document=${documentSearch}`: ''}`);
          }
        });
    } else {
      // If market parameter is defined
      get<Market>('market', 'search', 'query', { market: marketSearch, lang: lgSearch?.replace('_', '-') })
        .then(res => {
          navigate(`?lg=${lgSearch}&market=${marketSearch}${documentSearch ? `&document=${documentSearch}`: ''}`);
        })
    }
    
    // Update i18n library
    if (lgSearch) {
      i18n.changeLanguage(lgSearch.replace('_','-'));
    }
  }, [searchParams.get('lg'), searchParams.get('market'), searchParams.get('document')]);

  const getComponent = () => {
    const matches = window.location.search.match(/document=([^&]*)/);
    if (!matches && window.location.pathname === '/')
      return <HomePage search={search} />

    if (matches !== null)
      return <BookPage />
       
    return <Outlet context={{ search }} />;
  }

  return (
    <>
      <Header search={search} onSearch={(event) => setSearch(event.target.value)} clearSearch={() => setSearch('')} />
      <Grid item xs={12} container sx={{ height: searchParams.get('document') ? '90%' : 'auto', overflowX: 'hidden' }}>
        {getComponent()}
      </Grid>
    </>
  );
}