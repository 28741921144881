import React, { useContext } from 'react';
import { Badge, Grid, IconButton } from '@mui/material';
import { css } from '@emotion/css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CountryFlag } from './countryFlag';
import { Download } from '@mui/icons-material';
import { Box } from '@mui/system';
import { LangContext, useLangContext } from 'context/useLang';
import { MarketContext, useMarketContext } from 'context/useMarketContext';
import { Document } from 'models/document';
import { get, getAll } from 'services/axios';
import { DocumentVersion } from 'models/documentVersion';
import { Market } from 'models/market';
import { Language } from 'models/language';

interface CardCatalogProps {
  document: Document;
  isNew?: boolean;
}

export function CardCatalog(props: CardCatalogProps) {
  const { document, isNew = false } = props;

  const navigate = useNavigate();

  const [flags, setFlags] = React.useState<DocumentVersion[]>([]);

  React.useEffect(() => {
    getAll('document', undefined, 'param', { id: document.id })
      .then((res) => setFlags((res.data as Document).documentVersion as DocumentVersion[]));
  }, []);

  /**
   * Update lang, market and redirect to Flipping book
   * @param element 
   */
  const handkeClickLanguage = (language: Language, market: Market) => {
    get<number>('market', 'count', 'param', { code: language.code })
      .then(res => {
        if (res.data > 1) {
          // If default language is present than one more market, we add market in url too
          navigate(`?lg=${language.code?.replace('-', '_')}&market=${market.code}&document=${document.name}`);
        } else {
          // If default language is present to one market, only language code is enought
          navigate(`?lg=${language.code?.replace('-', '_')}&document=${document.name}`);
        }
      })
  }

  const handleOnclickDocument = () => {
    navigate(`${window.location.search}&document=${document.name}`)
  }

  return (
    <React.Suspense>
      <div className={css`
        position: relative;
      `}>
        {isNew &&
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{ position: 'absolute', left: ' 50%', '& .MuiBadge-badge': { backgroundColor: '#F27506' } }}
            color='primary'
            badgeContent='NEW' />
        }

        <img src={`${process.env.REACT_APP_AXIOS_URL}/catalog/document/image/${document.documentVersion[0].marketLanguage.market.code}/${document.documentVersion[0].marketLanguage.language.code}/${document.documentVersion[0].cover}`} className={css`width: 100%;`} />

        <Grid container item xs={12} direction='row' alignContent='center' justifyContent='center'
          sx={{ backgroundColor: 'transparent', position: 'absolute', top: '0', height: '80%', zIndex: '999', cursor: 'pointer' }}>
          <Box onClick={handleOnclickDocument} sx={{ flexGrow: 1, height: '100%' }} />
        </Grid>
        <Grid container
          item xs={12} direction='row' alignContent='center' justifyContent='center'
          sx={{ backgroundColor: 'rgba(0,0,0, .75)', position: 'absolute', bottom: '3px', minHeight: '20%', zIndex: '999', color: 'white' }}>
          <Grid item container xs={10} justifyContent='center'>
            <Grid item xs={10}>
              <Box onClick={() => handleOnclickDocument()} className={css`
              cursor: pointer;
              font-size: 18px; 
              font-weight: 600;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              &:first-letter {
                text-transform: uppercase;
              }
              padding: 5px 0;
            `}>
                {document.documentVersion[0].label}
              </Box>
            </Grid>
            <Grid container item xs={10} py={'5px'}>
              {flags.map((version, y) =>
                <div key={y} className={css`&:hover { cursor: pointer; } padding: 3px 0;`}
                  onClick={() => handkeClickLanguage(version.marketLanguage.language, version.marketLanguage.market)}>
                  <Grid item pr={1}>
                    <CountryFlag lang={version.marketLanguage.language.code} />
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={2}>
            <IconButton onClick={() => window.open(`${window.location.origin}/books/${document.name}/${document.documentVersion[0].marketLanguage.market.code}/${document.documentVersion[0].marketLanguage.language.code}/files/assets/common/downloads/publication.pdf`, '_blank',)}>
              <Download className={css`color: white;`} />
            </IconButton>
          </Grid>
          {/* Description */}
        </Grid>
      </div>
    </React.Suspense>
  )
}