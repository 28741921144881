import { RecordVoiceOver } from '@mui/icons-material';
import axios, {AxiosResponse} from 'axios';

/**
 * 
 */
export type CATEGORY_URL = 'category';
/**
 * 
 */
export type DOCUMENT_URL = 'document'
export type DOCUMENT_URL_search = 'search';
/**
 * 
 */
export type MARKET_URL = 'market';
export type MAKRET_URL_FindById = 'findById';
export type MARKET_URL_Search = 'search'
export type MARKET_URL_SearchById = 'searchById'
/**
 * 
 */
export type LANGUAGE_URL = 'language'
export type LANGUAGE_URL_Count = 'count'
/**
 * 
 */
export type GET_REQUEST_TYPE_QUERY = 'query'
/**
 * 
 */
export type GET_REQUEST_TYPE_PARAM = 'param'
/**
 * 
 */
type GET_REQUEST_TYPE = GET_REQUEST_TYPE_PARAM | GET_REQUEST_TYPE_QUERY;

/**
 * 
 */
type URL_SERVICE = CATEGORY_URL | MARKET_URL | LANGUAGE_URL | DOCUMENT_URL; 
type SUB_URL = MAKRET_URL_FindById | MARKET_URL_Search | LANGUAGE_URL_Count | MARKET_URL_SearchById;

/**
 * 
 */
export interface GET_PROTOTYPE { 
  <T>(url: URL_SERVICE,
      subUrl?: SUB_URL,
      type?: GET_REQUEST_TYPE | undefined,
      params?: Record<string, any>): Promise<AxiosResponse<T, any>>
  <T>(url: URL_SERVICE,
    subUrl?: SUB_URL,
    type?: GET_REQUEST_TYPE | undefined,
    params?: Record<string, any>): Promise<AxiosResponse<T, any>>
}

/**
 * Put request prototypes
 */
export interface IPut {
  <T>(url: URL_SERVICE, object: Partial<T>): Promise<AxiosResponse<T, any>>;
}


/**
 * Get axios instance and config base
 * @returns 
 */
export function getAxiosInstance() {
  return axios.create({
    baseURL: process.env.REACT_APP_AXIOS_URL + '/catalog/',
    headers: {
      'Authorization' : `Bearer ${window.localStorage.getItem('token')}`
    }
  })
}

/**
 * 
 * @param url 
 * @param params 
 * @returns 
 */
// export function getAll(url: URL_SERVICE, params?: Record<string, any> | undefined): Promise<AxiosResponse<any, any>> {
//   return getAxiosInstance()
//   .get(url.toString(), { params: params})
// }

/**
 * 
 * @param url 
 * @param params 
 * @returns 
 */
// export function get(
//   url: URL_SERVICE, 
//   suburl: string, 
//   params: Record<string, any>): Promise<AxiosResponse<any, any>> {

//   return getAxiosInstance()
//   .get(url.toString() + '/' + suburl, { params: params})
// }

export const get = function get<T>(
  url: URL_SERVICE,
  subUrl?: SUB_URL,
  type?: GET_REQUEST_TYPE | undefined,
  params?: Record<string, any>
): Promise<AxiosResponse<T, any>> {  
  let urlService = `${url}${subUrl ? `/${subUrl}` : ''}`;
  return getAxiosInstance().get(
    type === 'param' ? transfromParamToUrlParam(urlService, params) : urlService, 
    {
    ...type === 'query' ? { params: params } : {}
    })
} as GET_PROTOTYPE;

export const getAll = function getAll<T>(
  url: URL_SERVICE,
  subUrl?: SUB_URL,
  type?: GET_REQUEST_TYPE | undefined,
  params?: Record<string, any>
): Promise<AxiosResponse<T, any>> {
  let urlService = `${url}${subUrl ? `/${subUrl}` : ''}`;
  return getAxiosInstance().get(
    type === 'param' ? transfromParamToUrlParam(urlService, params) : urlService, 
    {
    ...type === 'query' ? { params: params } : {}
    })
} as GET_PROTOTYPE;

const transformParamToQueryString = (url: string, param: Record<string, any>): string => url += `?${new URLSearchParams(param).toString()}`;
const transfromParamToUrlParam = (url: string, param?: Record<string, any>) => url += Object.entries(param ?? {}).map(([key, value]) => `/${value}`).join('')


