import { Market } from 'components/component';
import React from 'react';

export interface MarketContext {
  /**
   * 
   */
  market: Market[];
  /**
   * 
   */
  setMarket: Function;
  /**
   * 
   */
  currentMarket: Market;
  /**
   * 
   */
  setCurrentMarket: Function;
}

/**
 * 
 */
const context = React.createContext<MarketContext>({
  market: [],
  setMarket: () => {},
  currentMarket: {id: 0, label: '', language: [], code: ''},
  setCurrentMarket: () => {}
});

/**
 * 
 * @returns 
 */
export function useMarketContext() {
  return context;
}