import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IPost } from './post';
import { IPut } from './put';
import { IDelete } from './delete';
import { UrlService } from './url';

// Catch response success or errors 
axios.interceptors.response.use(
  // Nothing to do for all success response
  (response: AxiosResponse<any, any>) => {
    return response;
  }, 
  // Catch the error(s) and display her/them
  (error: unknown) => {
    // TODO => Display error(s)
  }
)

const transformParamToQueryString = (url: string, params?: Record<string, any>): string => url += params ? `?${new URLSearchParams(params).toString()}` : '';


/**
 * Get axios instance with default configuration
 * @returns 
 */
export const instance = (): AxiosInstance => axios.create({
  baseURL: process.env.REACT_APP_AXIOS_URL + '/catalog/',
  headers: {
    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
  }
})

/**
 * 
 */
export const put = function Put<T>(
  url: UrlService, object: Partial<T>, sub_url?: string, params?: Record<string, any>
): Promise<AxiosResponse<T, any>> {
  return instance().put(
    transformParamToQueryString(`${url}${sub_url ? `/${sub_url}` : '' }`, params), 
    object
  );
} as IPut 

/**
 * 
 */
export const post = function<T>(
  url: UrlService, object: Partial<T>, sub_url?: string, params?: Record<string, any>
): Promise<AxiosResponse<T, any>> {
  return instance().post(
    transformParamToQueryString(`${url}${sub_url ? `/${sub_url}` : '' }`, params), 
    object
  );
} as IPost

export const del = function<T>(
  url: UrlService, id: number, action: string
): Promise<AxiosResponse<T, any>> {
  return instance().delete(`${url}/${action}/${id}`)
} as IDelete