import { css } from '@emotion/css';
import { Home } from '@mui/icons-material';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CountryFlag } from 'components/countryFlag';
import { Document } from 'models/document';
import { version } from 'os';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { get } from 'services/axios';

export function BookPage() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  
  const [document, setDocument] = React.useState<Document | null>()
  const [lg, setLg] = React.useState<string | null>()
  const [market, setMarket] = React.useState<string | null>()

  React.useEffect(() => {
    const lgSearch = searchParams.get('lg')?.replace('_', '-');
    setLg(lgSearch);
    setMarket(searchParams.get('market'));
    get<Document>('document', 'search', 'param', { name: searchParams.get('document'), langue: lgSearch }).then(res => setDocument(res.data));
  }, []);

  React.useEffect(() => {
    setDocument(null);
    const lgSearch = searchParams.get('lg')?.replace('_','-');
    setLg(lgSearch);
    get<Document>('document', 'search', 'param', { name: searchParams.get('document'), langue: lgSearch }).then(res => setDocument(res.data));
  }, [searchParams.get('lg'), searchParams.get('market')]);

  const handleClickHome = () => {
    const lgSearch = searchParams.get('lg')?.replace('_','-');
    const marketSearch = searchParams.get('market');

    const lg = document?.documentVersion.find(version => version.marketLanguage.language.code === lgSearch)?.marketLanguage.language.code;
    const market = document?.documentVersion.find(version => version.marketLanguage.market.code === marketSearch)?.marketLanguage.market.code;

    get<number>('market', 'count', 'param', { code: lg })
      .then(res => {
        if (res.data > 1) {
          // If default language is present than one more market, we add market in url too
          navigate(`?lg=${lg?.replace('-', '_')}&market=${market}`);
        } else {
          // If default language is present to one market, only language code is enought
          navigate(`?lg=${lg?.replace('-', '_')}`);
        }
      })
  }

  const handleOnClickLanguage = (langue: string, market: string) => {

    const documentSearch = searchParams.get('document');

    get<number>('market', 'count', 'param', { code: langue })
      .then(res => {
        if (res.data > 1) {
          // If default language is present than one more market, we add market in url too
          navigate(`?lg=${langue?.replace('-','_')}&market=${market}${documentSearch ? `&document=${documentSearch}`: ''}`);
        } else {
          // If default language is present to one market, only language code is enought
          navigate(`?lg=${langue?.replace('-','_')}${documentSearch ? `&document=${documentSearch}`: ''}`);
        }
      })
  }

  const handleOnClickCategory = (category: number) => {
    const lgSearch = searchParams.get('lg')?.replace('_','-');
    const marketSearch = searchParams.get('market');

    const lg = document?.documentVersion.find(version => version.marketLanguage.language.code === lgSearch)?.marketLanguage.language.code;
    const market = document?.documentVersion.find(version => version.marketLanguage.market.code === marketSearch)?.marketLanguage.market.code;

    get<number>('market', 'count', 'param', { code: lg })
      .then(res => {
        if (res.data > 1) {
          // If default language is present than one more market, we add market in url too
          navigate(`?lg=${lg?.replace('-', '_')}&market=${market}&category=${category}`);
        } else {
          // If default language is present to one market, only language code is enought
          navigate(`?lg=${lg?.replace('-', '_')}&category=${category}`);
        }
      })
  }

  const getUrlBook = () => {
    let version;

    if(market) {
      version = document?.documentVersion.find(version => version.marketLanguage.language.code === lg && version.marketLanguage.market.code === market)
    } else {
      version = document?.documentVersion.find(version => version.marketLanguage.language.code === lg)
    }

    if(version) {
      return `${process.env.PUBLIC_URL}/books/${document?.name}/${version.marketLanguage.market.code}/${version.marketLanguage.language.code}/index.html`
    }

    return '';
  }

  
  return (
    <>
      {document && lg && 
        <Grid container item xs={12} direction='column' flexWrap='nowrap'>
          <Grid item xs={0.5} py={2} px={2}>
            <Breadcrumbs sx={{ ml: '17.5px' }}>
              <Link sx={{ ':hover': { cursor: 'pointer' } }} underline='hover' onClick={handleClickHome}>
                <Home />
              </Link>
              <Link sx={{ ':hover': { cursor: 'pointer' } }} underline='hover' onClick={() => handleOnClickCategory(document.category.id)}>
                {document.category.categoryTranslation.label}
              </Link>
              <Link sx={{ ':hover': { cursor: 'pointer' } }} underline='hover'>
                {market && document.documentVersion.find(version => version.marketLanguage.language.code === lg && version.marketLanguage.market.code === market)?.label}
                {!market && document.documentVersion.find(version => version.marketLanguage.language.code === lg)?.label}
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sx={{ height: '82%', ml: '17.5px', mr: '17.5px', width: 'calc(100% - 35px)', maxWidth: 'calc(100% - 50px);' }}>
            <iframe height='100%' className={css`border: unset; width: calc(100%);`} src={getUrlBook()} />
          </Grid>
          <Grid container item xs={0.5} px={2} pt={2} pb={2}>
            <Grid item sx={{ ml: '17.5px', mr: '17.5px' }}>
              <Typography>{t('document.available')}</Typography>
            </Grid>
            {document.documentVersion.map((version, i) =>
              <Grid item key={i}>
                <Box onClick={() => handleOnClickLanguage(version.marketLanguage.language.code,version.marketLanguage.market.code)}>
                  <CountryFlag 
                    large displayFlag displayLabel 
                    lang={version.marketLanguage.language.code} 
                    label={version.marketLanguage.language.label} 
                    marketCode={version.marketLanguage.market.code} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      }
    </>
  )
}